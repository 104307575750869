/* import { getSpin } from '@/api'; */
import { uploadURL } from '@/utils/consts';
import imageURLs from '@/mixins/imageURLs';
export default {
  mixins: [imageURLs],
  data: () => ({
    uploadURL,
    ctx: null,
    canvas: null,
    holding: false,
    selectedImg: null,
    preloadedImages: [],
    lastLocation: null,
    countLoadedImages: 0,
  }),
  watch: {
    countLoadedImages(val) {
      if (this.spin.images360) {
        if (val === this.spin.images360.length) this.drawImage();
      }
    },
    preload(curr) {
      if (curr) {
        this.countLoadedImages = 0;
        this.preloadImages();
        this.$store.commit('SET_PRELOAD', false);
      }
    },
  },
  computed: {
    getPins() {
      return this.pins.filter((pin) => pin.imgIdx === this.selectedImg);
    },
    spin() {
      return this.$store.state.spin;
    },
    pins() {
      return this.$store.state.pins;
    },
    panoPins() {
      return this.$store.state.panoPins;
    },
    loadedPercentage() {
      if (this.spin.images360 && this.countLoadedImages) {
        return (this.countLoadedImages * 100) / this.spin.images360.length;
      }
      return 0;
    },
    preload() {
      return this.$store.state.preloadImages;
    },
  },
  created() {
    this.getSpin();
  },
  mounted() {
    this.canvas = document.getElementById('canvas');
    this.ctx = this.canvas.getContext('2d');
  },
  methods: {
    async getSpin() {
      /* this.spin = await getSpin(this.$route.params.id);
      this.pins = this.spin.hotspots.map((h) => h); */
      await this.$store.dispatch('getSpin', {
        id: this.$route.params.id,
        type: 'private',
      });
      this.preloadImages();
    },
    calculate(pin) {
      //`left: ${pin.position.x}px; top: ${pin.position.y}px;`
      const { width, height } = this.canvas.getBoundingClientRect();
      let x = this.getNewValue(pin.position.x, pin.canvasWidth, width);
      let y = this.getNewValue(pin.position.y, pin.canvasHeight, height);
      if (window.innerWidth <= 600) {
        x += 10;
        y += 10;
      }
      return `left: ${x}px; top: ${y}px;`;
    },
    getNewValue(posValue, oldCanvasSize, newCanvasSize) {
      return posValue * (newCanvasSize / oldCanvasSize) - 20;
    },
    getMousePos(canvas, evt) {
      var rect = canvas.getBoundingClientRect();
      return {
        canvasPosition: {
          x: evt.clientX - rect.left,
          y: evt.clientY - rect.top,
        },
        screenPosition: {
          x: evt.pageX - window.pageXOffset,
          y: evt.pageY - window.pageYOffset,
        },
      };
    },
    getTouchPos(canvasDom, touchEvent) {
      var rect = canvasDom.getBoundingClientRect();
      return {
        x: touchEvent.touches[0].clientX - rect.left,
        y: touchEvent.touches[0].clientY - rect.top,
      };
    },
    preloadImages() {
      console.log('PRELOAD');
      this.preloadedImages = [];
      this.spin.images360.forEach((image) => {
        const newImage = new Image();
        newImage.src = this.getImageURL(
          this.spin,
          'images_360',
          'resized',
          image
        );
        newImage.onload = () => this.countLoadedImages++;
        this.preloadedImages.push(newImage);
        this.selectedImg = 0;
      });
    },
    drawImage() {
      const img = this.preloadedImages[this.selectedImg];
      var canvas = this.ctx.canvas;
      var hRatio = canvas.width / img.width;
      var vRatio = canvas.height / img.height;
      var ratio = Math.min(hRatio, vRatio);
      var centerShift_x = (canvas.width - img.width * ratio) / 2;
      var centerShift_y = (canvas.height - img.height * ratio) / 2;
      this.ctx.clearRect(0, 0, canvas.width, canvas.height);
      this.ctx.drawImage(
        img,
        0,
        0,
        img.width,
        img.height,
        centerShift_x,
        centerShift_y,
        img.width * ratio,
        img.height * ratio
      );
    },
  },
};
